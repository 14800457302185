import clsx from 'clsx'
import Typography from 'components/Common/Typography'

const CountContainer = ({
  textClasses,
  value,
  color = '#fff',
}: {
  textClasses?: string
  value: number | string
  color?: string
}) => {
  const renderSvg = (direction: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
    >
      <path d={`M${direction}`} stroke={color} />
    </svg>
  )

  return (
    <div className="flex h-4 items-center gap-0.5">
      <div className="flex h-full flex-col justify-between">
        {renderSvg('1 4.58925V1H4.35391')}
        {renderSvg('1 0.410751V4H4.35391')}
      </div>
      <Typography font="font-number">
        <div className={clsx('text-xs leading-4', textClasses)}>{value}</div>
      </Typography>
      <div className="flex h-full flex-col justify-between">
        {renderSvg('3.70801 4.58925V1H0.354098')}
        {renderSvg('4 0.410751V4H0.64609')}
      </div>
    </div>
  )
}

export default CountContainer
