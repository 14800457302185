import { cn } from 'utils/classnames'

enum variants {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body-base-bold',
  'body-base-semi-bold',
  'body-base-medium',
  'body-base-regular',
  'body-sub-text-bold',
  'body-sub-text-semi-bold',
  'body-sub-text-medium',
  'body-sub-text-regular',
  'sm-regular',
  'sm-medium',
  'sm-bold',
}

type fonts = 'font-body' | 'font-number'

interface TypographyProps {
  children: React.ReactNode
  variant?: variants
  font?: fonts
  className?: string
}

const Typography = ({
  children,
  variant,
  font = 'font-body',
  className,
}: TypographyProps) => {
  return (
    <div
      className={cn(
        variant === variants.h1 && 'font-number text-[56px] font-bold',
        variant === variants.h2 && 'text-5xl font-bold',
        variant === variants.h3 && 'text-[40px] font-bold',
        variant === variants.h4 && 'text-[32px] font-bold',
        variant === variants.h5 && 'text-2xl font-bold',
        variant === variants.h6 && 'text-lg font-bold',
        variant === variants['body-base-bold'] && 'text-base font-bold',
        variant === variants['body-base-semi-bold'] &&
          'text-base font-semibold',
        variant === variants['body-base-semi-bold'] &&
          'text-base font-semibold',
        variant === variants['body-base-medium'] && 'text-base font-medium',
        variant === variants['body-base-regular'] && 'text-base font-normal',
        variant === variants['body-sub-text-bold'] && 'text-sm font-bold',
        variant === variants['body-sub-text-medium'] && 'text-sm font-medium',
        variant === variants['body-sub-text-regular'] && 'text-sm font-normal',
        variant === variants['sm-regular'] && 'text-xs font-normal',
        variant === variants['sm-medium'] && 'text-xs font-medium',
        variant === variants['sm-bold'] && 'text-xs font-bold',
        font === 'font-number' && '!font-normal',
        font,
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Typography
