import Input from '@components/forms/Input'
import tradeboxStore from '@store/tradeboxStore'
import clsx from 'clsx'
import { BASIS_POINTS_DIVISOR } from 'config/factors'
import { roundToTwoDecimals } from 'rfx/lib/numbers'
import { useState } from 'react'
import { cn } from 'utils/classnames'

const MAX_SLIPPAGE = 99 * 100
const HIGH_SLIPPAGE = 2 * 100
const SLIPPAGE_SUGGESTION_LISTS = [0.3, 0.5, 1, 1.5]
const validDecimalRegex = /^\d+(\.\d{0,2})?$/ // 0.00 ~ 99.99

export function getSlippageText(value: number) {
  return roundToTwoDecimals((value / BASIS_POINTS_DIVISOR) * 100).toString()
}

interface Props {
  defaultSlippage: number
  largeSize?: boolean
}

export default function SlippageInput({
  largeSize = false,
  defaultSlippage,
}: Props) {
  const defaultSlippageText = getSlippageText(defaultSlippage)
  const [slippageText, setSlippageText] = useState<string>(defaultSlippageText)
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false)
  const updateProp = tradeboxStore((store) => store.updateProp)
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    if (value === '') {
      setSlippageText(value)
      updateProp('slippage', defaultSlippage)
      return
    }

    const parsedValue = Math.round(Number.parseFloat(value) * 100)
    if (Number.isNaN(parsedValue)) {
      return
    }

    if (parsedValue >= MAX_SLIPPAGE) {
      updateProp('slippage', MAX_SLIPPAGE)
      setSlippageText(getSlippageText(MAX_SLIPPAGE))
      return
    }

    if (validDecimalRegex.test(value)) {
      updateProp('slippage', parsedValue)
      setSlippageText(value)
    }
  }

  function getSlippageError() {
    const parsedValue = Math.round(Number.parseFloat(slippageText) * 100)
    if (parsedValue >= HIGH_SLIPPAGE) {
      return 'Slippage is too high'
    }
  }

  return (
    <div>
      <div
        className={clsx(
          largeSize ? 'h-8 w-[90px]' : '!h-7 w-20',
          !!getSlippageError() && 'border-th-error',
        )}
      >
        <Input
          id="slippage-input"
          onFocus={() => setIsPanelVisible(true)}
          onBlur={() => setIsPanelVisible(false)}
          value={!!slippageText ? slippageText : ''}
          placeholder={slippageText || defaultSlippageText}
          onChange={handleChange}
          suffix="%"
          className={cn('font-number', largeSize ? 'h-8' : 'h-7')}
        />
      </div>
      {isPanelVisible && (
        <ul>
          {SLIPPAGE_SUGGESTION_LISTS.map((slippage) => (
            <li
              key={slippage}
              onMouseDown={() => {
                setSlippageText(String(slippage))
                updateProp('slippage', slippage * 100)
                setIsPanelVisible(false)
              }}
            >
              {slippage}%
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
