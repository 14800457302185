import cx from 'classnames'
import classes from './StatsTooltip.module.css'
import Typography from 'components/Common/Typography'

export type StatsTooltipRowProps = {
  className?: string
  label: string | React.ReactNode
  value: number | string | string[] | number[] | React.ReactNode
  showDollar?: boolean
  unit?: string
  showColon?: boolean
}

export default function StatsTooltipRow({
  label,
  value,
  className,
  showDollar = true,
  unit,
  showColon = true,
}: StatsTooltipRowProps) {
  function renderValue() {
    if (Array.isArray(value)) {
      return (
        <ul className={classes['tooltip_row-values']}>
          {value.map((v, i) => (
            <li className={className} key={i}>
              <Typography font="font-number">{v}</Typography>
            </li>
          ))}
        </ul>
      )
    }
    return (
      <Typography
        font="font-number"
        className={cx(classes['tooltip_row-value'], className)}
      >
        {showDollar && '$'}
        {value}
        {unit || ''}
      </Typography>
    )
  }

  function renderLabel() {
    if (typeof label === 'string') {
      return showColon ? `${label}:` : label
    }

    return label
  }

  return (
    <div className={cx(classes.tooltip_row, className)}>
      <span className={classes['tooltip_row-label']}>{renderLabel()}</span>
      {renderValue()}
    </div>
  )
}
