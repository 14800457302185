import { ChangeEvent } from 'react'
import { cn } from 'utils/classnames'

function escapeSpecialRegExpChars(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)

type Props = {
  value?: string | number
  inputRef?: React.RefObject<HTMLInputElement>
  onValueChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  className?: string
  placeholder?: string
}

function NumberInput({
  value,
  inputRef,
  onValueChange,
  onFocus,
  onBlur,
  className,
  placeholder,
}: Props) {
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (!onValueChange) {
      return
    }
    const regex = /^\d*\.?\d{0,8}$/

    if (!regex.test(e.target.value)) {
      return
    }
    // Replace comma with dot
    if (!regex.test(e.target.value)) {
      return
    }
    let newValue = e.target.value.replace(/,/g, '.')
    if (newValue === '.') {
      newValue = '0.'
    }

    if (
      newValue === '' ||
      inputRegex.test(escapeSpecialRegExpChars(newValue))
    ) {
      e.target.value = newValue
      onValueChange(e)
    }
  }
  return (
    <input
      type="string"
      inputMode="decimal"
      placeholder={placeholder}
      className={cn('font-number', className)}
      value={value}
      ref={inputRef}
      onChange={onChange}
      autoComplete="off"
      autoCorrect="off"
      minLength={1}
      maxLength={15}
      spellCheck="false"
      onFocus={onFocus}
      onBlur={onBlur}
      pattern="^\d*(\.\d{0,4})?$"
    />
  )
}

export default NumberInput
